<template>
  <v-expand-transition>
    <!-- 1 == projects, 4 == news -->
    <div class="SubMenu same-padding-appbar in-submenu" v-if="show">
      <v-row v-if="changeContent == 1" no-gutters>
        <v-col cols="12" sm="3" class="line-height-18">
          <router-link
            class="color-black not-text-decoration"
            v-for="(category, cp) in categoriesProjects"
            v-bind:key="cp"
            v-bind:to="'/projects/' + category.name"
          >
            <span class="d-block titles hover-color">
              {{ firstLetterUppercase(category.name) }}
            </span>
          </router-link>
          <router-link to="/projects" class="color-black not-text-decoration SubMenu__padding-top-1 d-block">
            <span class="d-block hover-color">Todos los proyectos</span>
          </router-link>
        </v-col>

        <v-col cols="12" sm="9">
          <v-row>
            <v-col
              v-for="(project, prs) in projects"
              v-bind:key="prs"
              cols="12"
              sm="4"
              class="pb-0"
            >
              <v-card
                tile
                elevation="0"
                class="toClick SubMenu__card"
                :to="'/project/' + project.url"
                :ripple="false"
              >
                <template v-if="project.miniature">
                  <v-img
                    lazy-src
                    class="Projects--image-view-a"
                    :src="
                      'https://www.ofisdesigncms.actstudio.xyz/' +
                      project.miniature.path
                    "
                  ></v-img>
                </template>

                <v-card-text class="Project--line-height-texts px-0 py-1">
                  <v-row>
                    <v-col>
                      <div
                        v-html="project.name"
                        class="capitalize Projects__paragraphs titles-card-projects aeonik-bold color-black"
                      ></div>
                      <span
                        class="color-black titles-card-projects capitalize d-block"
                      >
                        {{ project.location }}
                      </span>
                      <span class="color-black titles-card-projects">
                        {{ numberWithCommas(project.meters) }}m<sup>2</sup>
                      </span>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-col>
      </v-row>

      <v-row v-if="changeContent == 4" no-gutters>
        <v-col cols="12" sm="3" class="line-height-18">
          <router-link
            class="color-black not-text-decoration"
            v-for="(category, cn) in categoriesNews"
            v-bind:key="cn"
            v-bind:to="'/news/' + category.name"
          >
            <span class="d-block titles hover-color">
              {{ firstLetterUppercase(category.name) }}
            </span>
          </router-link>

          <router-link class="color-black not-text-decoration SubMenu__padding-top-1 d-block" to="/news">
            <span class="d-block hover-color">Todas las noticias</span>
          </router-link>
        </v-col>

        <v-col cols="12" sm="9">
          <v-row>
            <v-col
              v-for="(post, pst) in news"
              v-bind:key="pst"
              cols="12"
              sm="4"
              class="pb-0"
            >
              <v-card
                tile
                elevation="0"
                class="toClick SubMenu__card"
                :to="'/new/' + post.url"
                :ripple="false"
              >
                <template v-if="post.miniature != null">
                  <v-img
                    lazy-src
                    class="Projects--image-view-a"
                    :src="
                      'https://www.ofisdesigncms.actstudio.xyz/' +
                      post.miniature.path
                    "
                  ></v-img>
                </template>

                <v-card-text class="Project--line-height-texts px-0 py-1">
                  <v-row>
                    <v-col>
                      <div class="SubMenu__max-width">
                        <span class="d-block black--text titles-card-projects">{{
                          post.description
                        }}</span>
                      </div>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </div>
  </v-expand-transition>
</template>

<script>
export default {
  name: "SubMenu",
  props: {
    show: { type: Boolean, required: true },
    changeContent: { type: Number, required: true },
  },
  computed: {
    projectsFiltered() {
      var projects = this.projects;
      var projectsF = [];
      var aux = this;

      if (this.nameCategoryProject != "") {
        for (let index = 0; index < projects.length; index++) {
          const element = projects[index];

          for (let index = 0; index < element.categories.length; index++) {
            const element2 = element.categories[index];
            if (element2.name == aux.nameCategoryProject) {
              projectsF.push(element);
              console.log(element2.name);
            }
          }
        }
      }

      if (projectsF.length < 1) {
        return projects;
      } else {
        return projectsF;
      }
    },
    newsFiltered() {
      var news = this.news;
      var aux = this;

      if (this.nameCategoryNew != "") {
        news = news.filter((post) => post.type.name == this.nameCategoryNew);
      }

      return news;
    },
  },
  data() {
    return {
      //projects
      nameCategoryProject: "",
      project: {},
      categoriesProjects: [],
      projects: [],

      //news
      nameCategoryNew: "",
      categoriesNews: [],
      news: [],
    };
  },
  beforeCreate() {
    //fetch for related projects
    fetch(
      "https://ofisdesigncms.actstudio.xyz/api/collections/get/Projects?token=e96ec97538f5b51ad80b44386e6d1c",
      {
        method: "post",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          populate: 2,
          limit: 3,
          sort: { _created: -1 },
        }),
      }
    )
      .then((res) => res.json())
      .then((res) => (this.projects = res.entries))
      .catch((error) => console.log(error.message));

    //fetch for categorie´s projects
    fetch(
      "https://ofisdesigncms.actstudio.xyz/api/collections/get/categoriesProject?token=e96ec97538f5b51ad80b44386e6d1c",
      {
        method: "post",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({}),
      }
    )
      .then((res) => res.json())
      .then((res) => (this.categoriesProjects = res.entries))
      .catch((error) => console.log(error.message));

    //fetch para las noticias
    fetch(
      "https://www.ofisdesigncms.actstudio.xyz/api/collections/get/news?token=e96ec97538f5b51ad80b44386e6d1c",
      {
        method: "post",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          filter: {},
          sort: { datePost: -1 },
          populate: 2,
          limit: 3,
        }),
      }
    )
      .then((res) => res.json())
      .then((res) => (this.news = res.entries))
      .catch((error) => console.log(error.message));

    //fetch for categorie´s news
    fetch(
      "https://ofisdesigncms.actstudio.xyz/api/collections/get/newCategories?token=e96ec97538f5b51ad80b44386e6d1c",
      {
        method: "post",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({}),
      }
    )
      .then((res) => res.json())
      .then((res) => (this.categoriesNews = res.entries))
      .catch((error) => console.log(error.message));
  },
  methods: {
    firstLetterUppercase: function (text) {
      return text.charAt(0).toUpperCase() + text.slice(1);
    },
    numberWithCommas: function(x) {
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
  },
};
</script>