<template>
  <v-app>
    <Header :headerUpdate="menu" />

    <v-main v-bind:class="{ 'padding-0': menu == 0 }">
      <transition name="fade-transition">
        <router-view @update="updateMenu" />
      </transition>
    </v-main>

    <Footer />

    <div class="cursor"></div>
  </v-app>
</template>

<script>
import Header from "./components/General/Header";
import Footer from "./components/General/Footer";

export default {
  name: "App",
  components: {
    Header,
    Footer,
  },
  data: () => ({
    menu: 0,
  }),
  mounted() {
    //global cursor
    window.addEventListener("mousemove", this.bodyCursor);
  },
  created() {
    //global animation for the scroll
    window.addEventListener("scroll", this.showInScroll);
  },
  methods: {
    updateMenu: function (x) {
      this.menu = x;
    },
    bodyCursor: function (e) {
      //variable para el cursor global
      var cursor = document.querySelector(".cursor");

      //variable para el footer
      var yellowElements = document.querySelectorAll(".yellow-element");

      //variable del div para la flecha de next
      var arrowStudio = document.querySelector(".cursor-right");

      //variable para cada asset
      var toClickAll = document.querySelectorAll(".toClick");

      //variable para el cursor del next en project
      var arrowNextProject = document.querySelector(".next-project");

      //variable para los espacios
      var whiteSpaces = document.querySelectorAll(".white-space");

      //cursor del logo
      var cursorLogo = document.querySelectorAll(".cursor-custom-logo");

      //cursor del sitio
      cursor.style.left = e.pageX - 10 + "px";
      cursor.style.top = e.pageY - (scrollY + 10) + "px";

      //in some places
      if(cursorLogo){
        cursorLogo.forEach((block)=>{
          block.addEventListener("mouseover", ()=>{
            cursor.classList.add("add-cursor");
          });

          block.addEventListener("mouseleave", ()=>{
            cursor.classList.remove("add-cursor");
          });
        });
      }

      //in white-spaces
      if (whiteSpaces) {
        whiteSpaces.forEach((wht) => {
          wht.addEventListener("mouseover", () => {
            cursor.classList.add("d-none-mouse");
          });
          wht.addEventListener("mouseleave", () => {
            cursor.classList.remove("d-none-mouse");
          });
        });
      }

      //in section studio
      if (arrowStudio) {
        arrowStudio.addEventListener("mouseover", () => {
          cursor.classList.add("arrow-next");
        });

        arrowStudio.addEventListener("mouseleave", () => {
          cursor.classList.remove("arrow-next");
        });
      }

      //in assets
      if (toClickAll) {
        toClickAll.forEach((tcl) => {
          tcl.addEventListener("mouseover", () => {
            cursor.classList.add("mouseClick");
          });

          tcl.addEventListener("mouseleave", () => {
            cursor.classList.remove("mouseClick");
          });
        });
      }

      // in project
      if (arrowNextProject) {
        arrowNextProject.addEventListener("mouseover", () => {
          cursor.classList.add("arrow-next-project");
        });

        arrowNextProject.addEventListener("mouseleave", () => {
          cursor.classList.remove("arrow-next-project");
        });
      }

      //in yellow elements
      if (yellowElements) {
        yellowElements.forEach((yellowElement) => {
          yellowElement.addEventListener("mouseleave", () => {
            cursor.classList.remove("on-yellow");
          });
          yellowElement.addEventListener("mouseover", () => {
            cursor.classList.add("on-yellow");
          });
        });
      }
    },
    //global animation for the scroll
    showInScroll: function (e) {
      var animationLazy = document.querySelectorAll(".animation-lazy");
      let scrollTop = document.documentElement.scrollTop;

      for (let index = 0; index < animationLazy.length; index++) {
        const element = animationLazy[index];
        let heightAnimate = element.offsetTop;

        if (heightAnimate - 500 < scrollTop) {
          // element.style.opacity = 1;
          element.classList.add("animation-lazy-load");
        }
      }
    },
  },
};
</script>
