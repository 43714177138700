<template>
  <div class="Header">
    <v-app-bar
      app
      light
      elevation="0"
      class="header-app"
      fixed
      :color="colorChange"
      :height="heightAppBar"
      @scroll="changeColorAppBar"
    >
      <div class="d-flex align-center">
        <template v-if="!changeLogoAppBar || submenu || menu_mobile">
          <router-link to="/">
            <v-img
              alt="OfisDesign logo"
              class="shrink mr-2 header-app--ofis-logo"
              contain
              src="@/assets/icons/ofis-logo-black.svg"
              transition="fade-transition"
            />
          </router-link>
        </template>

        <template v-else>
          <div
            class="header-app--logo-home"
            v-bind:class="{
              'color-white':
                headerUpdate == 0 || changeColorItems == false || !submenu,
              'color-black': changeColorItems || headerUpdate == 1 || submenu,
            }"
          >
            <span class="d-block titles aeonik-regular">Architecture</span>
            <span class="d-block titles aeonik-regular">& Interior</span>
            <span class="d-block titles aeonik-regular">Design</span>
            <!-- {{submenu}} -->
          </div>
        </template>
      </div>

      <v-spacer></v-spacer>

      <!-- inicia barra del menú en desk -->
      <div class="d-none d-md-block">
        <template v-for="(itemH, ith) in itemsHeader">
          <router-link
            class="not-text-decoration header-app--items-links"
            v-bind:to="itemH.url"
            v-bind:key="ith"
            v-bind:class="{
              'color-white':
                headerUpdate == 0 || changeColorItems == false || !submenu,
              'color-black': changeColorItems || headerUpdate == 1 || submenu,
            }"
          >
            <span
              class="texts capitalize aeonik-regular"
              @mouseover="openMenu(ith)"
              >{{ itemH.text }}</span
            >
          </router-link>
        </template>
      </div>
      <!-- /termina barra del menú en desk -->

      <!-- inicia barra del menú en mobile -->
      <div class="d-md-none">
        <v-img src="@/assets/icons/menu-mobile.svg" @click="menu_mobile= !menu_mobile"></v-img>
      </div>
      <!-- /termina barra del menú en mobile -->
    </v-app-bar>

    <MenuMobile :show="menu_mobile" :itemsNav="itemsHeader"></MenuMobile>

    <div @mouseleave="submenu = false">
      <SubMenu :show="submenu" :changeContent="changeContent"></SubMenu>
    </div>
  </div>
</template>

<script>
import SubMenu from "../Statics/SubMenu";
import MenuMobile from '../Statics/MenuMobile';

export default {
  name: "Header",
  components: {
    SubMenu,
    MenuMobile
  },
  props: {
    headerUpdate: { type: Number, required: true },
  },
  data() {
    return {
      colorChange: "transparent",
      changeColorItems: false,
      changeLogoAppBar: true,
      carouselHomeIntro: 0,
      heightAppBar: 0,
      itemsHeader: [
        {
          text: "Studio",
          url: "/studio",
        },
        {
          text: "Projects",
          url: "/projects",
        },
        {
          text: "Designers",
          url: "/designers",
        },
        {
          text: "Awards",
          url: "/awards",
        },
        {
          text: "News",
          url: "/news",
        },
        {
          text: "Contact",
          url: "/contact",
        },
      ],
      //para submenu
      submenu: false,
      changeContent: 0,
      //menú mobile
      menu_mobile: false
    };
  },
  watch: {
    $route(to, from){
      this.submenu = false;
      this.menu_mobile = false;
    },
    headerUpdate: function (value) {
      if (this.headerUpdate == 0) {
        this.colorChange = "transparent";
        this.changeLogoAppBar = true;
      } else if (this.headerUpdate == 1) {
        this.changeLogoAppBar = false;
        this.colorChange = "#FFFFFF";
      }
    },
  },
  mounted() {
    //resize about height carousel
    this.onResize();
    window.addEventListener("resize", this.onResize, { passive: true });

    //event scroll
    window.addEventListener("scroll", this.changeColorAppBar);
  },
  beforeDestroy() {
    if (typeof window !== "undefined") {
      window.addEventListener("resize", this.onResize, { passive: true });
    }
  },
  methods: {
    onResize() {
      //height del appbar
      if (window.innerWidth < 599) {
        this.heightAppBar = 45;
      } else if (window.innerWidth > 600) {
        this.heightAppBar = 70;
      }
    },
    changeColorAppBar: function (e) {
      if (this.headerUpdate == 0) {
        if (document.documentElement.scrollTop > 10) {
          this.colorChange = "#FFFFFF";
          this.changeColorItems = true;
          this.changeLogoAppBar = false;
        } else if (document.documentElement.scrollTop == 0) {
          this.colorChange = "transparent";
          this.changeColorItems = false;
          this.changeLogoAppBar = true;
        }
      } else if (this.headerUpdate == 1) {
        this.colorChange = "#FFFFFF";
        this.changeColorItems = false;
      }
    },
    openMenu: function (x) {
      if (x == 1 || x == 4) this.submenu = true;
      else this.submenu = false;

      this.changeContent = x;
    },
  },
};
</script>