<template>
  <div class="Footer">
    <v-footer class="footer-custom yellow-element">
      <v-container class="py-0">
        <v-row justify="space-between">
          <v-col cols="12" sm="auto" class="pb-0 pb-sm-3">
            <v-img src="@/assets/icons/logo-ofis-footer.svg" class="footer-custom--logo-ofis" />
          </v-col>
          <v-col cols="12" sm="auto">
            <v-row no-gutters class="fill-height" align="center">
              <v-col cols="12" sm="auto" class="text-center text-sm-left mt-4 mb-8 pr-sm-6">
                <span class="aeonik-regular x-small-texts">Follow us at</span>
              </v-col>
              <v-col cols="12" sm="auto" class="text-center text-sm-left">
                <template v-for="(socialNetwork, sn) in itemsSocialNetworks">
                  <a
                    v-bind:href="socialNetwork.link"
                    v-bind:key="sn"
                    v-bind:class="{'pr-0' : sn == 3}"
                    target="_blank"
                    class="d-inline-block footer-custom--social-networks "
                  >
                    <v-img :src="socialNetwork.icon" v-bind:class="{'facebook-icon': sn == 0, 'instagram-icon': sn == 1, 'pinterest-icon': sn == 2, 'linkedin-icon': sn == 3}"></v-img>
                  </a>
                </template>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        
        <!-- inician items para navegar desk  -->
        <v-row class="d-none d-sm-block">
          <v-col class="py-0">
            <v-row>
              <v-col cols="auto" class="footer-custom--line-height-1">
                <template v-for="(itemH, ith) in itemsFooter">
                  <template v-if="ith < 3">
                    <router-link
                      :to="itemH.url"
                      :key="ith+'-footer'"
                      class="not-text-decoration d-block footer-custom--items-links aeonik-light"
                    >
                      <span class="texts capitalize">{{itemH.text}}</span>
                    </router-link>
                  </template>
                </template>
              </v-col>
              <v-col cols="auto" class="footer-custom--line-height-1">
                <template v-for="(itemH, ith) in itemsFooter">
                  <template v-if="ith >= 3">
                    <router-link
                      :to="itemH.url"
                      :key="ith+'-footer-2'"
                      class="not-text-decoration d-block footer-custom--items-links aeonik-light"
                    >
                      <span class="texts capitalize">{{itemH.text}}</span>
                    </router-link>
                  </template>
                </template>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <!-- /terminan items para navegar desk -->
        
        <v-row>
          <v-col class="text-center text-sm-left">
            <div>
              <span class="aeonik-regular x-small-texts">Términos y condiciones</span>
              <span class="aeonik-regular px-3">|</span>
              <span class="aeonik-regular x-small-texts">Aviso de privacidad</span>
              <span class="aeonik-regular px-3 d-none d-sm-inline">|</span>
              <br class="d-sm-none">
              <span class="aeonik-regular x-small-texts">Política de privacidad</span>
            </div>
            <div>
              <span class="aeonik-regular x-small-texts">&copy; Ofisdesign 2020. Todos los derechos reservados.</span>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </v-footer>
  </div>
</template>
<script>
import facebook from "@/assets/icons/facebook.svg";
import instagram from "@/assets/icons/instagram.svg";
import pinterest from "@/assets/icons/pinterest.svg";
import linkedin from "@/assets/icons/linkedin.svg";

export default {
  name: "Footer",
  data() {
    return {
      //social networks
      itemsSocialNetworks: [
        {
          link: 'https://www.facebook.com/ofisdesign',
          icon: facebook,
        },
        {
          link: 'https://www.instagram.com/ofis_design/',
          icon: instagram,
        },
        {
          link: '',
          icon: pinterest,
        },
        {
          link: '',
          icon: linkedin,
        },
      ],
      //items footer to views
      itemsFooter: [
        {
          text: "Studio",
          url: "/studio",
        },
        {
          text: "Projects",
          url: "/projects",
        },
        {
          text: "Designers",
          url: "/designers",
        },
        {
          text: "Awards",
          url: "/awards",
        },
        {
          text: "News",
          url: "/news",
        },
        {
          text: "Contact",
          url: "/contact",
        },
      ],
    };
  },
};
</script>