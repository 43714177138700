<template>
  <v-container class="px-8 px-sm-12 px-md-3 py-0 animation" style="animation-delay: 2s;">
    <v-row justify="space-between">
      <template v-if="changeContent == 1">
        <!-- pendiente -->
        <v-col cols="12" sm="auto" v-for="(post, pst) in news" v-bind:key="pst">
          <v-card
            class="card-news"
            elevation="0"
            tile
            :ripple="false"
            :to="'/new/' + post.url"
          >
            <v-row no-gutters>
              <v-col cols="12">
                <template v-if="post.miniature != null">
                  <v-img
                    :src="
                      'https://www.ofisdesigncms.actstudio.xyz/' +
                      post.miniature.path
                    "
                    class="img-miniature"
                  ></v-img>
                </template>
              </v-col>

              <v-col cols="12" class="text-center">
                <v-card-text class="line-height-texts texts-news">
                  <span class="black--text titles d-block">
                    {{ post.description }}
                  </span>
                  <span
                    class="d-block mt-5 texts-news--span aeonik-regular black--text titles-card-projects"
                    >{{ post.type.name }}</span
                  >
                </v-card-text>
              </v-col>
            </v-row>
          </v-card>
        </v-col>
      </template>

      <template v-else-if="changeContent == 2">
        <v-col
          cols="12"
          sm="auto"
          class="col-scroll"
          v-for="(post, pst) in newsComputed"
          v-bind:key="pst"
          v-bind:class="selectClass(pst)"
        >
          <v-card
            class="card-news"
            elevation="0"
            tile
            :ripple="false"
            :to="'/new/' + post.url"
          >
            <v-row no-gutters>
              <v-col cols="12">
                <template v-if="post.miniature != null">
                  <v-img
                    :src="
                      'https://www.ofisdesigncms.actstudio.xyz/' +
                      post.miniature.path
                    "
                    class="img-miniature"
                  ></v-img>
                </template>
              </v-col>

              <v-col cols="12" class="text-center">
                <v-card-text class="line-height-texts texts-news">
                  <span class="black--text titles d-block">
                    {{ post.description }}
                  </span>
                  <span
                    class="d-block mt-5 texts-news--span aeonik-regular black--text titles-card-projects"
                    >{{ post.type.name }}</span
                  >
                </v-card-text>
              </v-col>
            </v-row>
          </v-card>
        </v-col>
      </template>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "News",
  props: {
    allowLimit: { type: Boolean, required: false },
    limit: { type: Number, required: true },
    filterByName: { type: String, required: false },
    changeContent: { type: Number, required: true },
  },
  computed: {
    newsComputed: function () {
      var news = this.news;

      if (this.filterByName != "" || this.$route.params.filter) {
        news = news.filter(
          (post) =>
            post.type.name == this.filterByName ||
            post.type.name == this.$route.params.filter
        );
      }

      return news;
    },
  },
  data() {
    return {
      news: [],
    };
  },
  mounted() {
    var limited;

    if (this.allowLimit) limited = this.limit;
    else limited = 20;

    //fetch para las noticias
    fetch(
      "https://www.ofisdesigncms.actstudio.xyz/api/collections/get/news?token=e96ec97538f5b51ad80b44386e6d1c",
      {
        method: "post",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          filter: {},
          sort: { datePost: -1 },
          populate: 2,
          limit: limited,
        }),
      }
    )
      .then((res) => res.json())
      .then((res) => (this.news = res.entries))
      .catch((error) => console.log(error.message));
  },
  methods: {
    selectClass: function (value) {
      if (value <= 6) {
        return "animation";
      } else {
        return "animation-lazy";
      }
    },
  },
};
</script>